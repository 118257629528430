// eslint-disable-next-line import/prefer-default-export
export const fieldsModal = [
  {
    key: 'status_ticket',
    label: 'Status Tiket',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
  {
    key: 'ticket_type',
    label: 'Jenis Tiket',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
  },
]

export const statusTicket = [
  {
    label: 'Menunggu Tindak Lanjut',
    value: 0,
    onCheck: false,
  },
  {
    label: 'Perlu Diproses',
    value: 1,
    onCheck: false,
  },
  {
    label: 'Sedang Diproses',
    value: 2,
    onCheck: false,
  },
  {
    label: 'Selesai',
    value: 3,
    onCheck: false,
  },
]
