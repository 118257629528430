import {
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import { mapFields } from 'vuex-map-fields'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import moment from 'moment'
import {
  today,
  last7,
  last30,
  last60,
  firstDateOfMonth,
  lastDateOfMonth,
  komshipDate,
  last120,
} from '@/store/helpers'
import secureLocalStorage from '@/libs/secureLocalstorage'
import BadgeJuragan from '@/views/components/badge/BadgeJuragan.vue'
import BadgeSaudagar from '@/views/components/badge/BadgeSaudagar.vue'
import BadgeUrgent from '@/views/components/badge/BadgeUrgent.vue'
import BadgeAttachement from '@/views/components/badge/BadgeAttachement.vue'
import ModalDownloadTicket from './ModalDownloadTicket.vue'

const firebaseConfig = {
  apiKey: 'AIzaSyCPYJYeP-9_G3S5MOV_-8QPDSmxF8dj84g',
  authDomain: 'komship-ticketing.firebaseapp.com',
  projectId: 'komship-ticketing',
  storageBucket: 'komship-ticketing.appspot.com',
  messagingSenderId: '669211426801',
  appId: '1:669211426801:web:55bca3d2dac7238b298e50',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app)

navigator.serviceWorker.register('firebase-messaging-sw.js', {
  scope:
  'firebase-cloud-messaging-push-scope',
})
  .then(registration => {
    messaging.useServiceWorker(registration)
  }).catch(() => {})

export default
{
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    vSelect,
    DateRangePicker,
    ValidationProvider,
    ValidationObserver,
    ModalDownloadTicket,
    BadgeJuragan,
    BadgeSaudagar,
    BadgeUrgent,
    BadgeAttachement,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isVariant: '',
      totalUnread: 0,
      totalUrgent: 0,
      isApplyUrgentFilter: false,
      isApplyFUSFilter: false,
      loadingDataTable: true,
      fieldsTicket: [
        {
          key: 'ticket_no',
          label: 'No. Tiket',
        },
        {
          key: 'no_resi',
          label: 'Nomor Resi',
        },
        {
          key: 'brand_name',
          label: 'Seller',
        },
        {
          key: 'ticket_type',
          label: 'Jenis Tiket',
        },
        {
          key: 'ticket_status',
          label: 'Status Tiket',
        },
        {
          key: 'date_created',
          label: 'Waktu Dibuat',
        },
        {
          key: 'date_updated',
          label: 'Waktu Diupdate',
        },
      ],
      itemsTicket: [],
      itemsResi: [],

      // Date range picker
      picker: {
        startDate: komshipDate,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      ranges: {
        'Semua ': [komshipDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      komshipDate,
      imageFile: null,
      imageInitialFile: null,
      itemsImageInitialFile: [],
      selected: true,
      selectMode: 'single',
      perluDiProses: 0,
      perluTindakLanjut: 0,
      sedangDiProses: 0,
      searchType: {
        label: 'Nomor Tiket',
        value: 'ticket_no',
      },
      searchItems: [
        {
          label: 'Nomor Tiket',
          value: 'ticket_no',
        },
        {
          label: 'Nomor Resi',
          value: 'no_resi',
        },
        {
          label: 'Seller',
          value: 'seller_name',
        },
      ],

      // Validation
      required,
      disableButton: false,

      // Store
      loadingSubmitTicket: false,
      itemsNoResi: null,
      noResi: null,
      customerName: '',
      jenisTicketItems: [
        {
          label: 'Alamat penerima / Nomor HP tidak lengkap',
          value: 5,
        },
      ],
      ticketTypeItems: [],
      ticketType: null,
      description: '',
      file: null,

      // Filter
      unreadTicket: [],
      ticketStatus: [],
      ticketStatusItems: [
        {
          label: 'Menunggu Tindak Lanjut',
          value: 0,
          onCheck: false,
        },
        {
          label: 'Perlu Diproses',
          value: 1,
          onCheck: false,
        },
        {
          label: 'Sedang Diproses',
          value: 2,
          onCheck: false,
        },
        {
          label: 'Selesai',
          value: 3,
          onCheck: false,
        },
      ],
      priorityItems: [
        {
          label: 'User Prioritas',
          value: 'UserPrioritas',
          onCheck: false,
        },
      ],
      priority: [],
      fontClassTicketStatus: {
        color: 'salmon',
      },
      search: '',
      filterTicketType: [],
      dateRange: {},
      dateRangeUpdate: {
        startDate: last120,
        endDate: today,
      },
      fcmToken: '',

      // Pagination
      currentPage: 1,
      totalRows: 0,
      optionsPage: [250, 500],
      totalPerPage: 250,

      // count ticket status
      finished: 0,

      userId: secureLocalStorage.get('userData'),
      colMessage: '2',
      unreadMode: false,

      dropdownFilter: false,
      totalFollowUpSeller: 0,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchTicket()
      },
    },
  },
  created() {
    this.receiveMessage()
  },
  computed: {
    computedClassFilter() {
      let result = '12'
      if (window.screen.width >= 600) {
        result = 'auto'
      }
      return result
    },
    computedClassTypeSearch() {
      return window.screen.width >= 600 ? 'pr-0' : 'pr-0 mb-1'
    },
    computedClassAnyFilter() {
      return window.screen.width >= 600 ? 'auto' : '12 mb-1'
    },
    ...mapFields('deliverConstraints', ['ticket_type_list']),
  },
  async mounted() {
    await this.defaultFilter()
    await this.fetchTicket()
    await this.fetchTicketMitraCount()
    await this.fetchTicketType()
    await this.fetchDataFirebase()
    await this.$store.dispatch('deliverConstraints/getTicketTypeList')
    Notification.requestPermission().then(permission => {
      if (!('permission' in Notification)) {
        Notification.permission = permission
      }
    })
  },
  methods: {
    defaultFilter() {
      this.ticketStatusItems.forEach((item, index) => {
        if (item.value === 1 || item.value === 2) {
          this.ticketStatusItems[index].onCheck = true
          this.ticketStatus.push(item.value)
        }
      })
      this.dateRange = {
        startDate: last60,
        endDate: today,
      }
    },
    submitFilter(value) {
      if (value === 'FUS') {
        this.isApplyFUSFilter = !this.isApplyFUSFilter
        this.ticketStatus = []
        // eslint-disable-next-line no-plusplus
        for (let x = 0; x < this.ticketStatusItems.length; x++) {
          this.ticketStatusItems[x].onCheck = false
        }
        if (this.isApplyFUSFilter) {
          this.ticketStatusItems[2].onCheck = true
          if (!this.ticketStatus.includes(2)) {
            this.ticketStatus.push(2)
          }
        } else {
          this.ticketStatusItems[2].onCheck = false
          this.ticketStatus = this.ticketStatus.filter(item => item !== 2)
        }
      }
      if (value === 'urgent') { this.isApplyUrgentFilter = !this.isApplyUrgentFilter }
      this.fetchTicket()
      this.isVariant = false
    },
    filterStatusFromBox(value) {
      this.ticketStatus = []
      this.ticketStatusItems = [
        {
          label: 'Menunggu Tindak Lanjut',
          value: 0,
          onCheck: false,
        },
        {
          label: 'Perlu Diproses',
          value: 1,
          onCheck: false,
        },
        {
          label: 'Sedang Diproses',
          value: 2,
          onCheck: false,
        },
        {
          label: 'Selesai',
          value: 3,
          onCheck: false,
        },
      ]
      this.ticketStatus.push(value)
      const findIndexObj = this.ticketStatusItems.findIndex(items => items.value === value)
      this.ticketStatusItems[findIndexObj].onCheck = true
      this.fetchTicket()
    },
    async fetchTicket() {
      this.loadingDataTable = true
      const params = {
        total_per_page: this.totalPerPage,
        page: this.currentPage,
      }
      if (this.dateRange) {
        Object.assign(params, { start_date: this.formatDateParams(this.dateRange.startDate) })
        Object.assign(params, { end_date: this.formatDateParams(this.dateRange.endDate) })
      }
      if (this.ticketStatus.length > 0) Object.assign(params, { ticket_status: this.ticketStatus.join() })
      if (this.search) Object.assign(params, { search: this.search })
      if (this.searchType) Object.assign(params, { search_type: this.searchType.value })
      if (this.filterTicketType.length > 0) Object.assign(params, { ticket_type: this.filterTicketType.join() })
      if (this.isApplyFUSFilter) { Object.assign(params, { unread: true }) }
      if (this.isApplyUrgentFilter || this.priority.length > 0) {
        let paramsPriority = ''
        if (this.isApplyUrgentFilter) {
          paramsPriority = 'Urgent'
        }
        if (this.priority.length > 0) {
          paramsPriority = this.priorityItems[0].value
        }
        if (this.isApplyUrgentFilter && this.priority.length > 0) {
          paramsPriority = `Urgent, ${this.priorityItems[0].value}`
        }
        Object.assign(params, { prioritas: paramsPriority })
      }
      await this.$http_komship.get('/v1/ticket-mitra/list', {
        params,
      })
        .then(response => {
          if (response.data.code !== 400) {
            const { data } = response.data.data
            this.itemsTicket = data
            this.totalRows = response.data.data.total
            this.loadingDataTable = false
          } else {
            this.itemsTicket = []
            this.loadingDataTable = false
          }
          if (this.isApplyFUSFilter) { this.getFollowUpSellerCount(params) }
          if (this.isApplyUrgentFilter) { this.getUrgentCount(params) }
        })
        .catch(err => {
          this.itemsTicket = []
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
          this.loadingDataTable = false
        })
    },
    fetchTicketMitraCount() {
      this.$http_komship.get('/v1/ticket-mitra/count')
        .then(response => {
          const { data } = response.data
          this.perluDiProses = data.belum_diproses
          this.perluTindakLanjut = data.perlu_tindak_lanjut
          this.sedangDiProses = data.sedang_diproses
          this.finished = data.selesai
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    fetchDataResi() {
      this.customerName = this.itemsNoResi.customer_name
      this.noResi = this.itemsNoResi.no_resi
    },
    alertSubmitTicket() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.$refs['alert-validate-ticket'].show()
        } else {
          this.loadingSubmitTicket = false
        }
      })
    },
    closeAlertSubmitTicket() {
      this.$refs['alert-validate-ticket'].hide()
    },
    submitTicket() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.$refs['loading-create-ticket'].show()
          this.$refs['modal-create-ticket'].hide()
          const formData = new FormData()
          formData.append('no_resi', this.noResi)
          formData.append('partner_name', this.customerName)
          formData.append('ticket_type', this.ticketType)
          formData.append('description', this.description)
          if (this.itemsImageInitialFile.length > 1) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.itemsImageInitialFile.length; i++) {
              formData.append('file[]', this.itemsImageInitialFile[i])
            }
          } else {
            formData.append('file[]', [])
          }

          this.$http_komship.post('/v2/ticket-mitra/store', formData)
            .then(() => {
              this.loadingSubmitTicket = false
              this.noResi = null
              this.itemsNoResi = null
              this.customerName = ''
              this.ticketType = null
              this.description = ''
              this.itemsImageInitialFile = []
              this.$refs['loading-create-ticket'].hide()
              this.$refs['popup-success-create-ticket'].show()
            })
            .catch(err => {
              this.$refs['loading-create-ticket'].hide()
              this.$refs['modal-failure-create-ticket'].show()
            })
        }
      })
    },
    closeSuccessCreateTicket() {
      this.fetchTicket()
      this.$refs['popup-success-create-ticket'].hide()
    },
    onRowSelected(data) {
      this.selected = data
      const url = this.$router.resolve({
        path: `/kendala-pengiriman/detail/${data[0].id}`,
        params: { data_tiket: data },
      })
      window.open(url.href, '_blank')
    },
    cekResi(search, loading) {
      if (search.length) {
        this.searchResi(loading, search, this)
      }
    },
    searchResi: _.debounce((loading, search, that) => {
      loading(true)
      that.loadResi(search).finally(() => loading(false))
    }, 500),
    loadResi(search) {
      return this.$http_komship.get(`/v1/ticket-mitra/check-resi/${search}`)
        .then(response => {
          if (response.data.code !== 400) {
            const { data } = response.data
            this.itemsResi = data
          } else {
            this.itemsResi = []
          }
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    formatDate(d) {
      return moment(d).format('D MMM YYYY')
    },
    formatDateParams(d) {
      return moment(d).format('YYYY-MM-DD')
    },
    onChangeFile(event) {
      event.target.files.forEach(this.myFile)
    },
    myFile(data) {
      this.itemsImageInitialFile.push(data)
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    deleteFile(data) {
      const findIndexObj = this.itemsImageInitialFile.findIndex(items => items.name === data.name)
      this.itemsImageInitialFile.splice(findIndexObj, 1)
    },
    setSearchType(data) {
      this.searchType = data
      this.fetchTicket()
      this.$root.$emit('bv::hide::popover', 'popover-search-type')
    },
    filterTicketByStatus(data) {
      const findIndexObj = this.ticketStatusItems.findIndex(items => items.value === data.value)
      const findObj = this.ticketStatus.findIndex(items => items === data.value)
      if (this.ticketStatusItems[findIndexObj].onCheck === true) {
        this.ticketStatus.push(data.value)
      } else {
        this.ticketStatus.splice(findObj, 1)
      }
    },
    filterByTicketType(data) {
      const findIndexObj = this.ticketTypeItems.findIndex(items => items.id === data.id)
      const findObj = this.filterTicketType.findIndex(items => items === data.id)
      if (this.ticketTypeItems[findIndexObj].onCheck === true) {
        this.filterTicketType.push(data.id)
      } else {
        this.filterTicketType.splice(findObj, 1)
      }
    },
    filterTicketByPriority(data) {
      const findIndexObj = this.priorityItems.findIndex(items => items.value === data.value)
      const findObj = this.priority.findIndex(items => items === data.value)
      if (this.priorityItems[findIndexObj].onCheck === true) {
        this.priority.push(data.value)
      } else {
        this.priority.splice(findObj, 1)
      }
    },
    async fetchTicketType() {
      this.ticketTypeItems = this.ticket_type_list
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.ticketTypeItems.length; x++) {
        Object.assign(this.ticketTypeItems[x], { onCheck: false })
      }
    },
    statusTicketClass(data) {
      let resultVariant = ''
      if (data === 1) {
        resultVariant = 'font-medium text-primary'
      } else if (data === 2) {
        resultVariant = 'font-medium text-warning'
      } else if (data === 3) {
        resultVariant = 'font-medium text-success'
      } else if (data === 4) {
        resultVariant = 'font-medium text-secondary'
      }
      return resultVariant
    },
    searchTicket: _.debounce(function () {
      this.fetchTicket()
    }, 1000),
    async clearFilter() {
      this.search = ''
      this.ticketStatus = []
      this.filterTicketType = []
      this.priority = []
      this.loadingDataTable = true
      this.isApplyUrgentFilter = false
      this.isApplyFUSFilter = false
      this.unreadMode = false
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.ticketTypeItems.length; x++) {
        this.ticketTypeItems[x].onCheck = false
      }
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.ticketStatusItems.length; x++) {
        this.ticketStatusItems[x].onCheck = false
      }
      for (let x = 0; x < this.priorityItems.length; x += 1) {
        this.priorityItems[x].onCheck = false
      }
      await this.defaultFilter()
      await this.fetchTicket()
    },
    receiveMessage() {
      try {
        onMessage(messaging, payload => {
          this.fetchTicket()
          this.fetchTicketMitraCount()
          this.fetchTicketType()
          this.fetchDataFirebase()
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      }
    },
    fetchDataFirebase() {
      Notification.requestPermission().then(permission => {
        if (!('permission' in Notification)) {
          Notification.permission = permission
        }
        // you got permission !
      }, rejection => {
        // handle rejection here.
      })
      getToken(messaging, { vapidKey: 'BLZr38POWZ6vwjTUx4v2vlPHK-3fiI-DMPY18tAbu1dpchDiAYMyR7l2PE3WbH5hOM55X2zBR_C-5BLrpUA1-ZM' }).then(currentToken => {
        if (currentToken) {
          Notification.requestPermission().then(permission => {
            if (permission === 'denied' || permission === 'default') {
              this.$refs['modal-alert-notification'].show()
            }
          })
          this.fcmToken = currentToken
          this.$http
            .post('/user/update-fcm-token', {
              user_id: this.userId.id,
              fcm_token: currentToken,
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'No registration token available. Request permission to generate one.',
              variant: 'danger',
            },
          }, 2000)
        }
      }).catch(err => {
        this.$refs['modal-alert-notification'].show()
      })
    },
    convertTicketStatus(data) {
      let result = ''
      if (data === 0) {
        result = 'Menunggu Tindak Lanjut'
      } else if (data === 1) {
        result = 'Perlu Diproses'
      } else if (data === 2) {
        result = 'Sedang Diproses'
      } else if (data === 3) {
        result = 'Selesai'
      } else if (data === 4) {
        result = 'Batal'
      }
      return result
    },
    clearFieldTicket() {
      this.noResi = null
      this.itemsResi = []
      this.itemsNoResi = null
      this.customerName = ''
      this.ticketType = null
      this.description = ''
      this.itemsImageInitialFile = []
      this.$refs.formRules.reset()
      this.$refs['modal-create-ticket'].hide()
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.fetchTicket()
    },
    checkVariantClear() {
      let result = 'outline-primary'
      if (this.itemsNoResi !== null || this.ticketType !== null || this.itemsImageInitialFile !== [] || this.description !== '') {
        result = 'outline-dark'
      }
      return result
    },
    checkDisableClear() {
      let result = true
      if (this.itemsNoResi !== null || this.ticketType !== null || this.itemsImageInitialFile !== [] || this.description !== '') {
        result = false
      }
      return result
    },
    handleCloseAlert() {
      this.$refs['modal-alert-notification'].hide()
    },
    showModalCreateTicket() {
      this.$refs['modal-create-ticket'].show()
    },
    getDateCreate(data) {
      const date = moment(data).format('DD MMMM YYYY')
      const result = `${date}`
      return result
    },
    getHoursCreate(data) {
      const hours = moment(data).format('HH.MM')
      const result = `${hours} WIB`
      return result
    },
    getDateUpdate(data) {
      const date = moment(data).format('DD MMMM YYYY')
      const result = `${date}`
      return result
    },
    getHoursUpdate(data) {
      const hours = moment(data).format('HH.MM')
      const result = `${hours} WIB`
      return result
    },
    handlePopupCreateTicket() {
      this.$refs['modal-create-ticket'].show()
      this.$refs['modal-failure-create-ticket'].hide()
    },
    getRowClass(item, type) {
      let result = null
      if (item) {
        result = item.history_ticket_count_partner !== null ? '' : 'table-secondary'
      }
      return result
    },
    getUrgentCount(params) {
      this.$http_komship
        .get('/v1/ticket-mitra/listurgent', { params })
        .then(response => {
          this.totalUrgent = response.data.data.total
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    getFollowUpSellerCount(params) {
      this.$http_komship
        .get('/v1/ticket-mitra/follow-up-seller', { params })
        .then(response => {
          this.totalFollowUpSeller = response.data.data.total
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
  },
}
